import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import the Navbar component
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Technology from './pages/Technology';
import TechnologyStrategy from './pages/TechnologyStrategy';
import Trustfabric from './pages/Trustfabric';
import CHADA from './pages/CHADA';
import CHADALinks from './pages/CHADALinks';
import './styles.css';

const App = () => (
    <Router>
        {/* Navbar will appear on all pages */}
        <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/technology" element={<Technology />} />
            <Route path="/technology-strategy" element={<TechnologyStrategy />} />
            <Route path="/trustfabric" element={<Trustfabric />} />
            <Route path="/chada" element={<CHADA />} />
            <Route path="/chadalinks" element={<CHADALinks />} />
            <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Routes>
    </Router>
);

export default App;

import React from 'react';

const Trustfabric = () => {
// Inline styles for the background
    const backgroundStyle = {
        backgroundImage: "url('./images/background9.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', // Parallax-like effect
        minHeight: '100vh', // Full height of the viewport
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        color: 'white',
    };

    const contentStyle = {
	backgroundColor: 'rgba(40, 35, 30, 0.75)',        
	//backgroundColor: 'rgba(0, 0, 0, 0.85)',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        maxWidth: '800px',
        color: 'white',
        textAlign: 'center',
    };

    return (
        <div style={backgroundStyle}>
            <div style={contentStyle}>
                <h1>Trustfabric - Welcome to the revolution</h1>
                <h3>Monetise Your Links Now</h3>
                <p>
                    The world of digital advertising is at a crossroads. For years, the industry has relied on outdated systems fraught with inefficiencies,
                    fraud, and opaque practices. Advertisers lose billions to fraudulent clicks, agents struggle to achieve fair compensation, and users are
                    subjected to irrelevant, intrusive ads. The status quo is no longer acceptable.
                </p>
                <p>
                    Enter CHADA, a transformative platform built on cutting-edge technology and underpinned by a visionary tokenized economy. The Core Hybrid
                    Architecture for Digital Advertising (CHADA) is not just a platform—it is the foundation of a new digital advertising ecosystem that
                    promises to revolutionize how advertisers, agents/influencers, and audiences connect.
                </p>
            </div>
        </div>
    );
};

export default Trustfabric;

import React from 'react';
import './Home.css';

const Home = () => {
    const homeBackgroundStyle = {
        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(/images/background4.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
    };

    return (
        <div style={homeBackgroundStyle}>
            <div>
                <div className="carbonText">CARBON</div>
                <div className="digitalText">
                    <span>D</span>
                    <span>I</span>
                    <span>G</span>
                    <span>I</span>
                    <span>T</span>
                    <span>A</span>
                    <span>L</span>
                </div>
            </div>
        </div>
    );
};

export default Home;

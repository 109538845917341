import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contactContainer">
            <h1>Contact Us</h1>
            <p>
                We would love to hear from you! Reach out to us at contact@carbondigital.net or fill out our contact form for any inquiries.
            </p>
        </div>
    );
};

export default Contact;

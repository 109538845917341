import React from 'react';
import './Services.css';

const Services = () => {
    return (
        <div className="servicesContainer">
            <h1>Our Services</h1>
            <p>
                At Carbon Digital, we provide a range of services including digital marketing, web development, and data analytics. Our team ensures that every service we deliver meets the highest standards.
            </p>
        </div>
    );
};

export default Services;

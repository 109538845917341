import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './Technology.css';

const Technology = () => {
    return (
        <div className="pageContainer">
            <h1>Technology</h1>
            <p>Welcome to the Technology section. Explore our various offerings:</p>
            <nav>
                <ul>
                    <li><Link to="strategy">Technology Strategy</Link></li>
                    <li><Link to="trustfabric">Trustfabric</Link></li>
                    <li><Link to="chada">CHADA</Link></li>
                    <li><Link to="chadalinks">CHADALinks</Link></li>
                </ul>
            </nav>
            <Outlet /> {/* Render nested routes here */}
        </div>
    );
};

export default Technology;
